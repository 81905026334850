<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <h3 class="text-h3 font-weight-bolder text-primary text-gradient mb-2">
        Solin logística
      </h3>
      <p class="mb-0 text-body">¿Olvidaste tu contraseña?</p>
    </div>
    <div class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Ingresa tu correo electrónico</label
      >
      <v-text-field
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="ejemplo@gmail.com"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
        v-model="inputs.email.value"
      >
      </v-text-field>
      <span class="text-caption error--text d-block">{{
        inputs.email.errors[0]
      }}</span>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          bg-gradient-primary
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="resetPass"
        :loading="loading"
      >
        Enviar
      </v-btn>
    </div>
  </v-card>
</template>
  <script>
import helpers from "../mixins/helpers";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "reestablecer-contraseña",
  mixins: [helpers],
  data() {
    return {
      inputs: {
        email: {
          value: "",
          errors: [],
        },
      },
      loading: false,
      
    };
  },
  mounted() {
    if (!this.existeXSRF()) {
      this.sanctumToken();  
    }
    
  },
  methods: {
    resetPass() {
      this.loading = true;
      this.resetErrors()
      let url = `${this.$base_api_url}/auth/send-recovery-password`;

      let data = {
        email: this.inputs.email.value,
      };
      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            title: `Éxito`,
            text: `Se mando un correo de recuperación de contraseña`,
            icon: `success`,
          });
          this.resetInputs();
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },

};
</script>
  
