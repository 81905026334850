
<!--PANEL DE INICIO DE CEDIS-->
<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col lg="4">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <v-row>
              <v-col cols="2"> </v-col>
              <v-col cols="8">
                <v-avatar
                  width="auto"
                  height="auto"
                  class="w-100 h-100 border-radius-lg"
                >
                  <img
                    src="@/assets/img/solin.png"
                    alt="Avatar"
                    class="w-200"
                  />
                </v-avatar>
                <p class="text-muted text-xl font-weight-bold mt-4 text-center">
                  Bienvenid@ a Solin logistica
                </p>
              </v-col>
              <v-col cols="2"> </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col lg="8">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <v-row>
              <v-col cols="10">
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  ¿Te equivocaste con algún archivo?
                </h5>
                <p>
                  Busca y selecciona el archivo que deseas eliminar, luego da
                  click en 'solicitar eliminación de archivo' y espera a que el
                  administrador lo elimine.
                </p>
                <v-autocomplete
                  :items="filesForRemove"
                  dense
                  filled
                  label="Busca por número de orden, nombre de archivo o cliente"
                  class="ml-4"
                  v-model="inputs.selectedFile.value"
                  :error="inputs.selectedFile.errors.length > 0"
                  :messages="inputs.selectedFile.errors"
                ></v-autocomplete>
                <v-btn
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-primary
                    bg-gradient-primary
                    py-5
                    px-6
                    mt-2
                    mb-2
                    me-2
                    ml-4
                  "
                  color="#1258E5"
                  @click="createRequestToRemoveFile"
                >
                  Solicitar eliminación de archivo
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!--TABLAS DE ARCHIVOS-->
    <!--SOLO SE MUESTRAN LOS ARCHICOS DEL CEDIS EN CUESTION-->
    <v-row>
      <v-col cols="12" class="mt-4">
        <v-container fluid class="py-6">
          <reports-table
            title="Archivos"
            subtitle="Listado de archivos, busca por cliente, cedis o número de orden."
            @file-was-deleted="getFiles"
            :files="files"
            disable-delete
          ></reports-table>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import axios from "axios";
import ReportsTable from "./Widgets/ReportsTable.vue";
import helpers from "../mixins/helpers";
axios.defaults.withCredentials = true;

export default {
  name: "inicio-cedis",
  mixins: [helpers],
  components: { ReportsTable },
  data() {
    return {
      files: [],
      inputs: {
        selectedFile: {
          value: "",
          errors: [],
          key: "file_id",
        },
      },
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      let url = `${this.$base_api_url}/files`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.files = res.data.files;
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {});
    },
    createRequestToRemoveFile() {
      this.showOverlay("Creando petición...");
      this.resetErrors();
      let url = `${this.$base_api_url}/request/files/add`;

      let data = {
        file_id: this.inputs.selectedFile.value,
      };
      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            text: `Se realizo la petición para eliminar el archivo, tu administrador aceptara o denegara esta petición`,
            icon: `success`,
          });
          this.resetInputs();
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },
  },
  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    filesForRemove() {
      return this.files.map((file) => ({
        value: file.id,
        text: `Cliente: ${file.client_name}, No de Orden: ${file.numero_de_orden}, Destino :${file.destino}`,
      }));
    },
  },
};
</script>
  