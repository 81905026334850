<template>
  <v-container fluid class="py-6">
    <v-row class="mb-8">
      <v-col sm="6" lg="4">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <v-row>
              <v-col cols="2"> </v-col>
              <v-col cols="8">
                <v-avatar
                  width="auto"
                  height="auto"
                  class="w-100 h-100 border-radius-lg"
                >
                  <img
                    src="@/assets/img/solin.png"
                    alt="Avatar"
                    class="w-200"
                  />
                </v-avatar>
                <p class="text-muted text-xl font-weight-bold mt-4 text-center">
                  Bienvenid@ a Solin logistica
                </p>
              </v-col>
              <v-col cols="2"> </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <reports-table
      title="Archivos"
      subtitle="Listado de archivos, busca por cliente, cedis o número de orden."
      @file-was-deleted="getFiles"
      :files="files"
      disable-delete
    ></reports-table>
  </v-container>
</template>
<script>
import axios from "axios";
import ReportsTable from "./Widgets/ReportsTable.vue";
import helpers from "../mixins/helpers";
axios.defaults.withCredentials = true;
export default {
  name: "paginated-tables",
  mixins: [helpers],
  components: { ReportsTable },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      files: [],
      search: "",
      headers: [
        {
          text: "CEDIS",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "cedis_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Cliente",
          value: "client_name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Fecha",
          value: "created_at",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Número de orden",
          value: "numero_de_orden",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Nombre de archivo",
          value: "file_name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Archivo",
          value: "archivos",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      let url = `${this.$base_api_url}/files`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.files = res.data.files;
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {});
    },
  },
  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.files.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
  