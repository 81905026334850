<template>
  <v-container fluid class="py-6">
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">Cobranza</h5>
        <p class="text-sm text-body mb-0">
          Lista de personal de cobranza agregado. Busca, crea y elimina.
        </p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="cobranza"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Buscar por nombre"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
                    >Nuevo</v-btn
                  >
                </template>
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">
                      Agregar
                    </span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="inputs.name.value"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="Nombre"
                          ></v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.name.errors[0] }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="inputs.email.value"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="Correo electrónico"
                          ></v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.email.errors[0] }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-gradient-light
                        py-3
                        px-6
                      "
                      >Cancelar</v-btn
                    >

                    <v-btn
                      @click="save"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                      >Agregar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                    >¿Eliminar?</v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeDelete"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls
                        bg-gradient-light
                        py-3
                        px-6
                      "
                      >Cancelar</v-btn
                    >

                    <v-btn
                      @click="deleteItemConfirm"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                      >Eliminar</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex align-center">
              <span class="text-sm font-weight-normal text-body">
                {{ item.name }}
              </span>
            </div>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.email }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click="deleteItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="red"
            >
              <v-icon size="12">ni-fat-remove text-lg</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Registros por por página</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#E58512"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
  <script>
import axios from "axios";
import helpers from "../mixins/helpers";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.withCredentials = true;

export default {
  name: "Cobranza",
  mixins: [helpers],
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      cobranza: [],
      search: "",
      userDelete: null,
      inputs: {
        email: {
          value: "",
          errors: [],
        },
        name: {
          value: "",
          errors: [],
        },
      },
      headers: [
        {
          text: "Cobranza",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Correo electrónico",
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7",
        },

        {
          text: "Eliminar",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.showOverlay("Descargando personal de cobranza...");

      let url = this.$base_api_url + `/users/collection`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.cobranza = res.data.users;
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },

    deleteItem(item) {
      this.userDelete = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.showOverlay("Eliminando personal...");
      let url = `${this.$base_api_url}/users/remove/${this.userDelete}`;

      let data = {
        _method: "DELETE",
      };

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            position: "top-end",
            text: `Se elimino correctamente el personal de cobranza`,
            icon: `success`,
            showConfirmButton: false,
            timer: 5000,
            backdrop: false,
          });
          this.getUsers();
          this.closeDelete();
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetInputs();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.showOverlay("Cargando nuevo personal de cobranza...");
      this.resetErrors();

      let url = `${this.$base_api_url}/users/store`;

      let data = {
        name: this.inputs.name.value,
        email: this.inputs.email.value,
        role: "collection",
      };
      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            position: "top-end",
            text: `Personal de cobranza creado con exito`,
            icon: `success`,
            showConfirmButton: false,
            timer: 5000,
            backdrop: false,
          });
          this.getUsers();
          this.resetInputs();
          this.close();
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.users.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
    