<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <h3 class="text-h3 font-weight-bolder text-primary text-gradient mb-2">
        Solin logística
      </h3>
      <p class="mb-0 text-body">Inicio de sesión</p>
    </div>
    <div class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Correo electrónico</label
      >
      <v-text-field
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="ejemplo@gmail.com"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
        v-model="inputs.email.value"
      >
      </v-text-field>
      <span class="text-caption error--text d-block">{{
        inputs.email.errors[0]
      }}</span>

      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Contraseña</label
      >
      <v-text-field
        v-model="inputs.password.value"
        :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
        :type="show1 ? 'text' : 'password'"
        name="input-10-1"
        placeholder="*************"
        @click:append="show1 = !show1"
        class="input-style text-color-light mb-4 mt-2"
        outlined
        v-on:keyup.enter="login"
      ></v-text-field>
      <span class="text-caption error--text d-block">{{
        inputs.password.errors[0]
      }}</span>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          bg-gradient-primary
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="login"
        :loading="loading"
      >
        Iniciar sesión
      </v-btn>
      <div class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          ¿Olvidaste tu contraseña? Recuperala
          <router-link
            to="/reestablecer-contrasena"
            class="text-primary text-gradient font-weight-bold"
          >
            aquí
          </router-link>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import helpers from "../mixins/helpers";
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "sign-up-cover",
  mixins: [helpers],
  data() {
    return {
      show1: false,
      inputs: {
        email: {
          value: "",
          errors: [],
        },
        password: {
          value: "",
          errors: [],
        },
      },
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch("getUser").then(() => {
      if (this.$store.getters.getUser !== null) {
        this.$router.push({ name: "Router" });
      }
    });
  },
  methods: {
    login() {
      this.loading = true;

      if (!this.existeXSRF()) {
        this.sanctumToken().then(() => {
          loginS();
        });
      } else {
        this.loginS();
      }
    },
    loginS() {
      this.resetErrors()
      const urlLogin = this.$base_api_url + `/auth/login`;
      let data = {
        password: this.inputs.password.value,
      };

      const value = this.inputs.email.value;
      if (value.includes('@')) {
        data['email'] = value;
      }else{
        data['username'] = value;
      }

      axios
        .post(urlLogin, data)
        .then(() => {
          this.$store.dispatch("getUser").then((...e) => {
            console.log(e);
            this.$router.push({ name: "Router" });
          });
        })
        .catch((e) => {
          this.catchResponse(e);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script> 


 