
<!--PANEL DE INICIO DE ADMINISTRADOR-->
<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col lg="4">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <v-row>
              <v-col cols="2"> </v-col>
              <v-col cols="8">
                <v-avatar
                  width="auto"
                  height="auto"
                  class="w-100 h-100 border-radius-lg"
                >
                  <img
                    src="@/assets/img/solin.png"
                    alt="Avatar"
                    class="w-200"
                  />
                </v-avatar>
                <p class="text-muted text-xl font-weight-bold mt-4 text-center">
                  Bienvenid@ a Solin logistica
                </p>
              </v-col>
              <v-col cols="2"> </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-4">
        <reports-table
          title="Archivos recientes"
          subtitle="Agrega el archivo de factura de transporte"
          @file-factura-was-added="getFiles"
          can-add-factura-transporte
          ref="ReportsTable"
          :files="files"
          :search-input="false"
          disable-delete
        ></reports-table>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
import ReportsTable from "./Widgets/ReportsTable.vue";
import axios from "axios";
import helpers from "../mixins/helpers";

export default {
  name: "Administrador",
  mixins: [helpers],
  components: {
    ReportsTable,
  },
  data() {
    return {
      files: [],
      alerts: [],
      dialogDelete: false,
      fileDelete: null,
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    fileDeleted() {
      this.getFiles();
    },
    getFiles() {
      let url = `${this.$base_api_url}/files`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.files = res.data.files;
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {});
    },
    deleteItem(item) {
      this.fileDelete = item.file_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.showOverlay("Eliminando archivos...");
      let url = `${this.$base_api_url}/files/delete/${this.fileDelete}`;

      let data = {
        _method: "DELETE",
      };

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            position: "top-end",
            text: `Se elimino correctamente el archivo`,
            icon: `success`,
            showConfirmButton: false,
            timer: 5000,
            backdrop: false,
          });
          this.getLastRequest();
          this.closeDelete();
          this.getFiles();
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>