<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12" class="text-center">
          <h3 class="text-h3 text-typo font-weight-bold mt-9">Nueva entrada</h3>
          <h5 class="text-h5 text-secondary font-weight-normal">
            Completa los pasos para una nueva entrada
          </h5>
        </v-col>
        <v-stepper
          alt-labels
          elevation="0"
          v-model="e1"
          class="bg-transparent overflow-visible w-100 mx-auto"
        >
          <v-row>
            <v-col lg="8" cols="12" class="mx-auto mt-9">
              <v-stepper-header class="shadow-0">
                <v-stepper-step step="1" color="#1258E5">
                  <span class="text-secondary font-weight-normal"
                    >Datos generales</span
                  >
                </v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step step="2" color="#1258E5">
                  <span class="text-secondary font-weight-normal"
                    >Otros datos</span
                  >
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" color="#1258E5">
                  <span class="text-secondary font-weight-normal"
                    >Más datos</span
                  >
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="4" color="#1258E5">
                  <span class="text-secondary font-weight-normal"
                    >Archivos</span
                  >
                </v-stepper-step>
              </v-stepper-header>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="8" cols="12" class="mx-auto">
              <v-stepper-items
                class="border-radius-xl overflow-hidden shadow-lg mt-5 mb-9"
              >
                <v-stepper-content
                  step="1"
                  class="bg-white border-radius-xl px-4 pt-4"
                >
                  <v-card>
                    <div>
                      <v-row class="mt-2">
                        <v-col sm="12" cols="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Cliente</label
                          >
                          <v-select
                            :items="clientes"
                            color="rgba(0,0,0,.6)"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-2
                              mb-0
                            "
                            outlined
                            hide-details=""
                            single-line
                            height="40"
                            v-model="inputs.client_id.value"
                          >
                          </v-select>
                          <span class="text-caption error--text d-block">
                            {{ inputs.client_id.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Destino</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="Destino"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            v-model="inputs.destino.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.destino.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Número de orden</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="Número de orden"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            v-model="inputs.numero_de_orden.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.numero_de_orden.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Confirmación</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="Confirmación"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            v-model="inputs.confirmacion.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.confirmacion.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Factura</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="Factura"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            v-model="inputs.factura.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.factura.errors[0] }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="text-end">
                      <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                          font-weight-bold
                          text-xs
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mt-2
                          mb-2
                          me-2
                        "
                        color="#1258E5"
                        @click="e1 = 2"
                      >
                        siguiente
                      </v-btn>
                    </div>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content
                  step="2"
                  class="bg-white border-radius-xl px-4 pt-4"
                >
                  <v-card>
                    <div>
                      <v-row class="mt-2">
                        <v-col sm="12" cols="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Cantidad de tarimas</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="#"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            type="number"
                            v-model="inputs.cantidadtarimas.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.cantidadtarimas.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Cantidad de cajas</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="#"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            type="number"
                            v-model="inputs.cantidadcajas.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.cantidadcajas.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Cantidad de tarima IN</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="#"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            type="number"
                            v-model="inputs.cantidadtarimain.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.cantidadtarimain.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Cantidad de tarima OUT</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="#"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            type="number"
                            v-model="inputs.cantidadtarimaon.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.cantidadtarimaon.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                          >
                            Rearmado de tarima por caja
                          </label>
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="#"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            type="number"
                            v-model="inputs.rearmado.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.rearmado.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Re-empLaye de tarima</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="#"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            type="number"
                            v-model="inputs.remplaye.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.remplaye.errors[0] }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="d-flex mt-10">
                      <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                          font-weight-bold
                          text-xs text-dark
                          btn-light
                          bg-gradient-light
                          py-5
                          px-6
                          mt-6
                          mb-2
                          ms-2
                        "
                        @click="e1 = 1"
                      >
                        Anterior
                      </v-btn>
                      <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                          font-weight-bold
                          text-xs
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mt-6
                          mb-2
                          me-2
                          ms-auto
                        "
                        color="primary"
                        @click="e1 = 3"
                      >
                        Siguiente
                      </v-btn>
                    </div>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content
                  step="3"
                  class="bg-white border-radius-xl px-4 pt-4"
                >
                  <v-card>
                    <div>
                      <v-row class="mt-2">
                        <v-col sm="12" cols="12" md="3">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Flete en 3.5 to</label
                          >
                          <v-switch
                            v-model="inputs.flete35.value"
                            class="ml-4"
                          ></v-switch>

                          <span class="text-caption error--text d-block">
                            {{ inputs.flete35.errors[0] }}
                          </span>
                        </v-col>
                        <v-col sm="12" cols="12" md="3">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Flete en Rabon</label
                          >
                          <v-switch
                            v-model="inputs.fleterabon.value"
                            class="ml-4"
                          ></v-switch>

                          <span class="text-caption error--text d-block">
                            {{ inputs.fleterabon.errors[0] }}
                          </span>
                        </v-col>
                        <v-col sm="12" cols="12" md="3">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Flete en Torthon</label
                          >
                          <v-switch
                            v-model="inputs.fletetorthon.value"
                            class="ml-4"
                          ></v-switch>

                          <span class="text-caption error--text d-block">
                            {{ inputs.fletetorthon.errors[0] }}
                          </span>
                        </v-col>
                        <v-col sm="12" cols="12" md="3">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Flete en trailer</label
                          >
                          <v-switch
                            v-model="inputs.fletetrailer.value"
                            class="ml-4"
                          ></v-switch>

                          <span class="text-caption error--text d-block">
                            {{ inputs.fletetrailer.errors[0] }}
                          </span>
                        </v-col>
                        <v-col sm="12" cols="12" md="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Cantidad de horas de estancia</label
                          >
                          <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="Horas de estancia"
                            class="
                              font-size-input
                              placeholder-lighter
                              border border-radius-md
                              mt-2
                              mb-4
                            "
                            v-model="inputs.horasestancia.value"
                          >
                          </v-text-field>
                          <span class="text-caption error--text d-block">
                            {{ inputs.horasestancia.errors[0] }}
                          </span>
                        </v-col>
                        <v-col sm="12" cols="12" md="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Maniobra</label
                          >
                          <v-switch
                            v-model="inputs.maniobra.value"
                            class="ml-4"
                          ></v-switch>

                          <span class="text-caption error--text d-block">
                            {{ inputs.maniobra.errors[0] }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="d-flex mt-10">
                      <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                          font-weight-bold
                          text-xs text-dark
                          btn-light
                          bg-gradient-light
                          py-5
                          px-6
                          mt-6
                          mb-2
                          ms-2
                        "
                        @click="e1 = 2"
                      >
                        Anterior
                      </v-btn>
                      <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                          font-weight-bold
                          text-xs
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mt-6
                          mb-2
                          me-2
                          ms-auto
                        "
                        color="primary"
                        @click="e1 = 4"
                      >
                        Siguiente
                      </v-btn>
                    </div>
                  </v-card>
                </v-stepper-content>

                <!--Step final-->
                <v-stepper-content
                  step="4"
                  class="bg-white border-radius-xl px-4 pt-4"
                >
                  <v-card>
                    <div>
                      <v-row class="text-center">
                        <v-col cols="10" class="mx-auto">
                          <p class="text-body mt-4">
                            Recuerda que los archivos que subas no se pueden
                            eliminar a menos de que lo haga un administrador.
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col sm="9" class="text-center mx-auto">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Archivo EXTRACCIÓN</label
                          >
                          <v-file-input
                            chips
                            truncate-length="15"
                            placeholder="Selecciona aquí para subir archivo extracción"
                            v-model="inputs.fileextraccion.value"
                          ></v-file-input>
                          <span class="text-caption error--text d-block">
                            {{ inputs.fileextraccion.errors[0] }}
                          </span>

                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Archivo EMBARQUE (Ciego)</label
                          >
                          <v-file-input
                            chips
                            truncate-length="15"
                            placeholder="Selecciona aquí para subir archivo embarque (ciego)"
                            v-model="inputs.fileembarque.value"
                          ></v-file-input>
                          <span class="text-caption error--text d-block">
                            {{ inputs.fileembarque.errors[0] }}
                          </span>

                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Archivo ODT</label
                          >
                          <v-file-input
                            chips
                            truncate-length="15"
                            placeholder="Selecciona aquí para subir archivo odt"
                            v-model="inputs.fileodt.value"
                          ></v-file-input>
                          <span class="text-caption error--text d-block">
                            {{ inputs.fileodt.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Archivo FACTURA</label
                          >
                          <v-file-input
                            chips
                            truncate-length="15"
                            placeholder="Selecciona aquí para subir archivo factura"
                            v-model="inputs.filefactura.value"
                          ></v-file-input>
                          <span class="text-caption error--text d-block">
                            {{ inputs.filefactura.errors[0] }}
                          </span>
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Archivo MANIOBRA</label
                          >
                          <v-file-input
                            chips
                            truncate-length="15"
                            placeholder="Selecciona aquí para subir archivo maniobra"
                            v-model="inputs.filemaniobra.value"
                          ></v-file-input>
                          <span class="text-caption error--text d-block">
                            {{ inputs.filemaniobra.errors[0] }}
                          </span>

                          <v-textarea
                            solo
                            name="input-7-4"
                            label="Escribe comentarios adicionales aquí"
                            class="mt-2"
                            v-model="inputs.comentarios.value"
                          ></v-textarea>
                          <span class="text-caption error--text d-block">
                            {{ inputs.comentarios.errors[0] }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="d-flex mt-10">
                      <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                          font-weight-bold
                          text-xs text-dark
                          btn-light
                          bg-gradient-light
                          py-5
                          px-6
                          mt-6
                          mb-2
                          ms-2
                        "
                        @click="e1 = 3"
                      >
                        Anterior
                      </v-btn>
                      <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                          font-weight-bold
                          text-xs
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mt-6
                          mb-2
                          me-2
                          ms-auto
                        "
                        color="primary"
                        @click="storeFile"
                      >
                        Finalizar
                      </v-btn>
                    </div>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-col>
          </v-row>
        </v-stepper>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
import _ from "lodash";
import helpers from "../mixins/helpers";
export default {
  name: "SubirArchivo",
  mixins: [helpers],
  data() {
    return {
      e1: 1,
      toggle_exclusive: 2,
      clientes: [],
      inputs: {
        client_id: {
          value: "",
          errors: [],
        },
        numero_de_orden: {
          value: "",
          errors: [],
        },
        destino: {
          value: "",
          errors: [],
        },
        confirmacion: {
          value: "",
          errors: [],
        },
        factura: {
          value: "",
          errors: [],
        },
        cantidadtarimas: {
          value: "",
          errors: [],
          key: "cantidad_de_tarimas",
        },
        cantidadcajas: {
          value: "",
          errors: [],
          key: "cantidad_de_cajas",
        },
        cantidadtarimain: {
          value: "",
          errors: [],
          key: "cantidad_de_tarima_in",
        },
        cantidadtarimaon: {
          value: "",
          errors: [],
          key: "cantidad_de_tarima_out",
        },
        rearmado: {
          value: "",
          errors: [],
          key: "rearmado_de_tarima_por_caja",
        },
        remplaye: {
          value: "",
          errors: [],
          key: "re_emplaye_de_tarima",
        },
        flete35: {
          value: false,
          errors: [],
          default: false,
          key: "flete_35_to",
        },
        fleterabon: {
          value: false,
          errors: [],
          default: false,
          key: "flete_rabon",
        },
        fletetorthon: {
          value: false,
          errors: [],
          default: false,
          key: "flete_torthon",
        },
        fletetrailer: {
          value: false,
          errors: [],
          default: false,
          key: "flete_trailer",
        },
        horasestancia: {
          value: "",
          errors: [],
          key: "cantidad_de_horas_en_estancia",
        },
        maniobra: {
          value: false,
          errors: [],
          default: false,
        },
        fileextraccion: {
          value: null,
          errors: [],
          default: null,
          key: "file_extraccion",
        },
        fileembarque: {
          value: null,
          errors: [],
          default: null,
          key: "file_embarque",
        },
        fileodt: {
          value: null,
          errors: [],
          default: null,
          key: "file_odt",
        },
        filefactura: {
          value: null,
          errors: [],
          default: null,
          key: "file_factura",
        },
        filemaniobra: {
          value: null,
          errors: [],
          default: null,
          key: "file_maniobra",
        },
        comentarios: {
          value: "",
          errors: [],
        },
      },
    };
  },
  mounted() {
    this.getClientes();
  },
  methods: {
    getClientes() {
      let url = `${this.$base_api_url}/users/client`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.clientes = _.map(res.data.users, (user) => ({
            value: user.id,
            text: user.name,
          }));
        })
        .catch((res) => {
          console.log(res);
        });
    },
    storeFile() {
      this.showOverlay("Cargando nuevo archivo");
      this.resetErrors();
      let url = `${this.$base_api_url}/files/store`;

      const data = new FormData();

      _.forEach(this.inputs, (input, key) => {
        let index = key;
        if (input.key !== undefined) {
          index = input.key;
        }
        let value = input.value;
        if (value === true || value === false) {
          value = value?1:0;
        }
        data.append(index, value ?? "");
      });

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            text: `Se añadio el nuevo archivo con exito`,
            icon: `success`,
          });
          this.e1 = 1;
          this.resetInputs();
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
          const errors = res.response.data.errors;

          const passFirstStep = _.some(errors, (e, index) => {
            return [
              "client_id",
              "numero_de_orden",
              "destino",
              "confirmacion",
              "factura",
            ].includes(index);
          });

          const passSecondStep = _.some(errors, (e, index) => {
            return [
              "cantidad_de_tarimas",
              "cantidad_de_cajas",
              "cantidad_de_tarima_in",
              "cantidad_de_tarima_out",
              "rearmado_de_tarima_por_caja",
              "re_emplaye_de_tarima",
            ].includes(index);
          });

          const passThithStep = _.some(errors, (e, index) => {
            return [
              "flete_35_to",
              "flete_rabon",
              "flete_torthon",
              "flete_trailer",
              "cantidad_de_horas_en_estancia",
              "maniobra",
            ].includes(index);
          });

          if (passFirstStep) {
            this.e1 = 1;
          } else if (passSecondStep) {
            this.e1 = 2;
          } else if (passThithStep) {
            this.e1 = 3;
          }
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },
  },
};
</script>
  