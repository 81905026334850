
<!--PANEL DE INICIO DE ADMINISTRADOR-->
<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col lg="4">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <v-row>
              <v-col cols="2"> </v-col>
              <v-col cols="8">
                <v-avatar
                  width="auto"
                  height="auto"
                  class="w-100 h-100 border-radius-lg"
                >
                  <img
                    src="@/assets/img/solin.png"
                    alt="Avatar"
                    class="w-200"
                  />
                </v-avatar>
                <p class="text-muted text-xl font-weight-bold mt-4 text-center">
                  Bienvenid@ a Solin logistica
                </p>
              </v-col>
              <v-col cols="2"> </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col lg="8">
        <v-card class="card-shadow border-radius-xl">
          <div class="pa-4 pb-0 mb-0">
            <h5 class="text-h5 text-typo font-weight-bold">
              Ups, alguien se equivocó de archivo
            </h5>
            <p class="pb-0 mb-0">
              Si deseas ver el archivo, ve a la seccion de archivos/ver archivos
              y buscalo con los datos que se muestran. Si deseas eliminarlo,
              pulsa el botón de 'Eliminar archivo'.
            </p>
            <br />
          </div>
          <v-card-text class="px-4 mt-1 pb-3">
            <v-alert
              :ripple="false"
              v-for="item in alerts"
              :key="item.id"
              close-icon="fas fa-times font-size-root text-white"
              @input="(v) => removeRequest(v, item.id)"
              dismissible
              elevation="5"
              color="red"
            >
              <span class="span-color-letra">
                <b>Cliente</b>
                {{ item.client_name }} |
                <b>Nombre:</b>
                {{ item.cedis_name }} |
                <b>Orden:</b>
                {{ item.numero_de_orden }}
              </span>

              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-eliminar-alert
                  py-5
                  px-6
                  mt-2
                  mb-2
                "
                color="white"
                @click="deleteItem(item)"
              >
                Eliminar archivo
              </v-btn>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-4">
        <reports-table
          title="Archivos recientes"
          subtitle="Elimina si el archivo es incorrecto, no hagas nada si es correcto."
          @file-was-deleted="fileDeleted"
          ref="ReportsTable"
          :files="files"
          :search-input="false"
        ></reports-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >¿Eliminar?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDelete"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Cancelar</v-btn
          >

          <v-btn
            @click="deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ReportsTable from "./Widgets/ReportsTable.vue";
import axios from "axios";
import helpers from "../mixins/helpers";

export default {
  name: "Administrador",
  mixins: [helpers],
  components: {
    ReportsTable,
  },
  data() {
    return {
      files: [],
      alerts: [],
      dialogDelete: false,
      fileDelete: null,
    };
  },
  mounted() {
    this.getLastRequest();
    this.getFiles();
  },
  methods: {
    fileDeleted() {
      this.getLastRequest();
      this.getFiles();
    },
    getFiles() {
      let url = `${this.$base_api_url}/files?limit=5`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.files = res.data.files;
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {});
    },
    getLastRequest() {
      let url = `${this.$base_api_url}/request/files`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.alerts = res.data.requests;
        })
        .catch((res) => {
          this.catchResponse(res);
          console.log(res);
        })
        .then(() => {});
    },
    removeRequest(value, id) {
      if (value === false) {
        let url = `${this.$base_api_url}/request/files/${id}/remove`;

        let data = {
          _method: "DELETE",
        };
        axios
          .post(url, data)
          .then((res) => {
            console.log(res);
            this.$swal.fire({
              position: "top-end",
              text: `Se elimino correctamente la petición`,
              icon: `success`,
              showConfirmButton: false,
              timer: 3000,
              backdrop: false,
            });
            this.getLastRequest();
          })
          .catch((res) => {
            console.log(res);
            this.catchResponse(res);
          });
      }
    },
    deleteItem(item) {
      this.fileDelete = item.file_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.showOverlay("Eliminando archivos...");
      let url = `${this.$base_api_url}/files/delete/${this.fileDelete}`;

      let data = {
        _method: "DELETE",
      };

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            position: "top-end",
            text: `Se elimino correctamente el archivo`,
            icon: `success`,
            showConfirmButton: false,
            timer: 5000,
            backdrop: false,
          });
          this.getLastRequest();
          this.closeDelete();
          this.getFiles()
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>