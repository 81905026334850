<template>
  <v-card class="card-shadow border-radius-xl">
    <v-card-text class="px-0 py-0">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          {{ title }}
        </h5>
        <p class="text-sm text-body mb-0">
          {{ subtitle }}
        </p>
      </div>
      <v-card-title v-show="searchInput">
        <v-toolbar flat height="80">
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar"
              >
                <template slot="prepend-inner">
                  <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="files"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        class="elevation-1"
        :search="search"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="mt-2">
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Flete en 3.5 to</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      {{ item.flete_35_to ? "SI" : "NO" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Flete en Rabon</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      {{ item.flete_rabon ? "SI" : "NO" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Flete en Torthon</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      {{ item.flete_torthon ? "SI" : "NO" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Flete en Trailer</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      {{ item.flete_trailer ? "SI" : "NO" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Horas de estancia</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      {{ item.cantidad_de_horas_en_estancia }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Maniobra</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      {{ item.maniobra ? "SI" : "NO" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2" >
                <!--TODO FACTURA DE TRANSPORTE-->
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Factura de transporte</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      <v-btn
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#2152ff"
                        :href="storagePrivateLink(item.file_factura_transporte)"
                        download
                        target="_blank"
                        v-if="item.file_factura_transporte"
                      >
                        <v-icon size="15">ni-archive-2</v-icon>
                      </v-btn>

                      <v-btn
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="warning"
                        v-else
                      >
                        <v-icon size="15">ni-archive-2</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Archivo extracción</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      <v-btn
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#2152ff"
                        :href="storagePrivateLink(item.file_extraccion)"
                        download
                        target="_blank"
                      >
                        <v-icon size="15">ni-archive-2</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Archivo embarque (ciego)</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      <v-btn
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#2152ff"
                        :href="storagePrivateLink(item.file_embarque)"
                        download
                        target="_blank"
                      >
                        <v-icon size="15">ni-archive-2</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Archivo ODT</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      <v-btn
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#2152ff"
                        :href="storagePrivateLink(item.file_odt)"
                        download
                        target="_blank"
                      >
                        <v-icon size="15">ni-archive-2</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Archivo factura</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      <v-btn
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#2152ff"
                        :href="storagePrivateLink(item.file_factura)"
                        download
                        target="_blank"
                      >
                        <v-icon size="15">ni-archive-2</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Archivo maniobra</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      <v-btn
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#2152ff"
                        :href="storagePrivateLink(item.file_maniobra)"
                        download
                        target="_blank"
                      >
                        <v-icon size="15">ni-archive-2</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      class="
                        text-center
                        font-weight-black
                        caption
                        text--secondary
                      "
                      >Comentarios</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-center">
                      {{ item.comentarios }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            elevation="0"
            :ripple="false"
            height="28"
            min-width="36"
            width="40"
            class="btn-ls me-2 my-2 rounded-sm"
            color="red"
            @click="deleteItem(item)"
          >
            <v-icon size="15">ni-fat-remove text-lg</v-icon>
          </v-btn>
        </template>
        <!--TODO FACTURA DE TRANSPORTE-->
        <template v-slot:[`item.uploadFactura`]="{ item }">
          <v-btn
            icon
            elevation="0"
            :ripple="false"
            height="28"
            min-width="36"
            width="40"
            class="btn-ls me-2 my-2 rounded-sm"
            :color="item.file_factura_transporte ? '#2152ff' : 'warning'"
            @click="askUploadFactura(item.id, item.file_factura_transporte)"
          >
            <v-icon size="15">ni-archive-2</v-icon>
          </v-btn>
        </template>
        <!------------------------------------->
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDatetime(item.created_at) }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >¿Eliminar?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDelete"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Cancelar</v-btn
          >

          <v-btn
            @click="deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--TODO FACTURA DE TRANSPORTE-->
    <v-dialog
      v-model="subirFactura"
      max-width="500px"
      :persistent="subirFacturaLoader"
      @click:outside="resetInputs('inputsFactura')"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          Añade Factura de transporte
        </v-card-title>
        <v-card-text>
          <v-file-input
            label="Factura de transporte"
            v-model="inputsFactura.file.value"
            :error="inputsFactura.file.errors.length > 0"
            :messages="inputsFactura.file.errors"
          ></v-file-input>
        </v-card-text>
        <v-card-actions class="pb-0">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            block
            :loading="subirFacturaLoader"
            @click="uploadFactura"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from "axios";
import helpers from "../../mixins/helpers";
axios.defaults.withCredentials = true;
export default {
  name: "light-table-transparent",
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    searchInput: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    canAddFacturaTransporte: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [helpers],
  data() {
    return {
      subirFactura: false,
      subitFacturaId: null,
      subirFacturaLoader: false,
      dialogDelete: false,
      fileDelete: null,
      expanded: [],
      singleExpand: false,
      search: "",
      headers: [
        {
          text: "Origen",
          align: "start",
          sortable: false,
          value: "cedis_name",
        },
        { text: "Destino", value: "destino", align: "center", sortable: false },
        {
          text: "Número de orden",
          value: "numero_de_orden",
          align: "center",
          sortable: false,
        },
        {
          text: "Fecha",
          value: "created_at",
          align: "center",
          sortable: false,
        },
        {
          text: "Tarimas",
          value: "cantidad_de_tarimas",
          align: "center",
          sortable: false,
        },
        {
          text: "Cajas",
          value: "cantidad_de_cajas",
          align: "center",
          sortable: false,
        },
        {
          text: "Tarimas IN",
          value: "cantidad_de_tarima_in",
          align: "center",
          sortable: false,
        },
        {
          text: "Tarimas OUT",
          value: "cantidad_de_tarima_out",
          align: "center",
          sortable: false,
        },
        {
          text: "Rearmado de tarimas por caja",
          value: "rearmado_de_tarima_por_caja",
          align: "center",
          sortable: false,
        },
        {
          text: "Re-empLaye de tarima",
          value: "re_emplaye_de_tarima",
          align: "center",
          sortable: false,
        },
        {
          text: "Factura",
          value: "factura",
          align: "center",
          sortable: false,
        },
        {
          text: "Más info y archivos",
          value: "data-table-expand",
          align: "center",
          sortable: false,
        },
        { text: "Eliminar", value: "actions", sortable: false },
        {
          text: "AÑADIR FACTURA",
          value: "uploadFactura",
          sortable: false,
          align: "center",
        },
      ],
      inputsFactura: {
        file: {
          value: null,
          errors: [],
          default: null,
        },
      },
    };
  },
  mounted() {
    if (this.disableDelete) {
      const index = _.findIndex(this.headers, { value: "actions" });
      if (index > -1) {
        this.headers.splice(index, 1);
      }
    }
    if (!this.canAddFacturaTransporte) {
      const index = _.findIndex(this.headers, { value: "uploadFactura" });
      if (index > -1) {
        this.headers.splice(index, 1);
      }
    }
  },
  methods: {
    askUploadFactura(id, file) {
      if (file === null) {
        this.subirFactura = true;
        this.subitFacturaId = id;
      }
    },
    uploadFactura() {
      const id = this.subitFacturaId;
      this.subirFacturaLoader = true;
      this.resetErrors("inputsFactura");
      let url = this.$base_api_url + `/files/factura-transporte/${id}`;

      const data = new FormData();
      data.append("file", this.inputsFactura.file.value ?? "");

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            title: `Éxito`,
            text: `Se subío correctamente la factura de transporte`,
            icon: `success`,
            showConfirmButton: false,
            timer: 3000,
            backdrop: false,
          });
          this.resetInputs("inputsFactura");
          this.subirFactura = false;
          this.$emit("file-factura-was-added");
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.subirFacturaLoader = false;
        });
    },
    deleteItem(item) {
      this.fileDelete = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.showOverlay("Eliminando archivo...");
      let url = `${this.$base_api_url}/files/delete/${this.fileDelete}`;

      let data = {
        _method: "DELETE",
      };

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            position: "top-end",
            text: `Se elimino correctamente el archivo`,
            icon: `success`,
            showConfirmButton: false,
            timer: 5000,
            backdrop: false,
          });
          this.closeDelete();
          this.$emit("file-was-deleted");
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
