
<!--PANEL DE INICIO DE CLIENTE-->
<template>
  <v-container fluid class="py-6">
    <!--TABLAS DE ARCHIVOS-->
    <!--SOLO SE MUESTRAN LOS ARCHICOS DEL CEDIS EN CUESTION-->
    <v-row>
      <v-col cols="12">
        <v-container fluid class="py-6">
          <reports-table
            title="Archivos"
            subtitle="Listado de archivos, busca por cliente, cedis o número de orden."
            @file-was-deleted="getFiles"
            ref="ReportsTable"
            :files="files"
          ></reports-table>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  
  <script>
import helpers from "../mixins/helpers";
import ReportsTable from "./Widgets/ReportsTable.vue";
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "inicio-cedis",
  components: { ReportsTable },
  mixins: [helpers],
  data() {
    return {
      fileDelete: null,
      files: [],
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      let url = `${this.$base_api_url}/files`;

      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.files = res.data.files;
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {});
    },
    deleteItem(item) {
      this.fileDelete = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.showOverlay("Eliminando archivos...");
      let url = `${this.$base_api_url}/files/delete/${this.fileDelete}`;

      let data = {
        _method: "DELETE",
      };

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal.fire({
            position: "top-end",
            text: `Se elimino correctamente el archivo`,
            icon: `success`,
            showConfirmButton: false,
            timer: 5000,
            backdrop: false,
          });
          this.closeDelete();
          this.getFiles();
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.files.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
    