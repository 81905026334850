
   <template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <h3 class="text-h3 font-weight-bolder text-primary text-gradient mb-2">
        Solin logística
      </h3>
      <p class="mb-0 text-body">Reestablece tu contraseña</p>
    </div>
    <div class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1">{{ name }}</label
      ><br />
      <label class="text-xs text-typo font-weight-bolder ms-1">{{
        email
      }}</label
      ><br /><br />

      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Contraseña</label
      >
      <v-text-field
        v-model="inputs.password.value"
        hide-details
        :append-icon="show2 ? 'fa-eye' : 'fa-eye-slash'"
        :type="show2 ? 'text' : 'password'"
        name="input-10-1"
        placeholder="*************"
        @click:append="show2 = !show2"
        class="input-style text-color-light mt-2"
        outlined
      ></v-text-field>
      <span class="text-caption error--text d-block mb-5">{{
        inputs.password.errors[0]
      }}</span>
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Confirmar contraseña</label
      >
      <v-text-field
        v-model="inputs.password_confirmation.value"
        hide-details
        :append-icon="show3 ? 'fa-eye' : 'fa-eye-slash'"
        :type="show3 ? 'text' : 'password'"
        name="input-10-1"
        placeholder="*************"
        @click:append="show3 = !show3"
        class="input-style text-color-light mt-2"
        outlined
      ></v-text-field>
      <span class="text-caption error--text d-block mb-5">{{
        inputs.password_confirmation.errors[0]
      }}</span>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          bg-gradient-primary
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="resetPassword"
      >
        Enviar
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import axios from "axios";
import helpers from "../mixins/helpers";
axios.defaults.withCredentials = true;

export default {
  name: "nueva-contraseña",
  mixins: [helpers],
  data() {
    return {
      show2: false,
      show3: false,
      inputs: {
        password: {
          value: "",
          errors: [],
        },
        password_confirmation: {
          value: "",
          errors: [],
        },
      },
      token: null,
      user: null,
    };
  },
  mounted() {
    if (!this.existeXSRF()) {
      this.sanctumToken().then(() => {
        this.token = this.$route.params.token;
        this.getInfoForToken();
      });
    } else {
      this.token = this.$route.params.token;
      this.getInfoForToken();
    }
  },
  methods: {
    getInfoForToken() {
      this.showOverlay("Verificando caducidad del link...");
      let url = `${this.$base_api_url}/auth/get-info-for-token/${this.token}`;

      const v = this;
      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.user = res.data.user;
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res, {
            customEvents: {
              404: function () {
                v.$swal
                  .fire({
                    title: `Ups`,
                    text: `Al parecer este link ya caduco, intenta generar otro.`,
                    icon: `warning`,
                  })
                  .then(() => {
                    v.$router.push({ name: "RestablecerContraseña" });
                  });
              },
            },
          });
        })
        .then((res) => {
          this.hiddenOverlay();
        });
    },
    resetPassword() {
      this.showOverlay("Restableciendo contraseña...");
      this.resetErrors();
      let url = `${this.$base_api_url}/auth/reset-password/${this.token}`;

      let data = {
        password: this.inputs.password.value,
        password_confirmation: this.inputs.password_confirmation.value,
      };

      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.$swal
            .fire({
              title: `Éxito`,
              text: `Se restablecio correctamente tu contraseña`,
              icon: `success`,
              confirmButtonText: "Iniciar sesión",
            })
            .then((data) => {
              this.$router.push({ name: "Login" });
            });
        })
        .catch((res) => {
          console.log(res);
          this.catchResponse(res);
        })
        .then(() => {
          this.hiddenOverlay();
        });
    },
  },
  computed: {
    name() {
      if (this.user !== null) {
        return this.user.name;
      } else {
        return "Nombre";
      }
    },
    email() {
      if (this.user !== null) {
        return this.user.email;
      } else {
        return "Correo";
      }
    },
  },
};
</script>
  